import { useQuery } from "@tanstack/react-query";
import { getDomains } from "../services/proxy";

const useHook = () => {
  return useQuery(["domains"], async () => {
    return getDomains();
  });
};

export default useHook;
