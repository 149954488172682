import { UserButton, useAuth } from "@clerk/clerk-react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import useUsers from "../hooks/useUsers";
import { client } from "../services/axios";
import { createUser } from "../services/proxy";
import DomainModel from "./domainModal";

export default function Home() {
  const [domainModalOpen, setDomainModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [email, setEmail] = useState("");
  const usersQuery = useUsers();
  const users = usersQuery.data ?? [];
  const { getToken } = useAuth();

  const handleAddUser = async () => {
    if (!email.length) return;
    await createUser(email);
    await usersQuery.refetch();
    setEmail("");
  };

  const columns: GridColDef[] = [
    { field: "email", headerName: "Email", flex: 4 },
    {
      field: "custom-1",
      headerName: "Domains",
      flex: 2,
      valueGetter: (params) => params.row.domains.length,
    },
    {
      field: "custom-2",
      headerName: "Details",
      flex: 1,
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              setSelectedUser(params.row);
              setDomainModalOpen(true);
            }}
          >
            <PencilIcon className="w-5 text-neutral-500" />
          </button>
        );
      },
    },
  ];

  const refreshHeaders = useCallback(async () => {
    const token = await getToken();
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }, [getToken]);

  useEffect(() => {
    refreshHeaders();
    const interval = setInterval(refreshHeaders, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <DomainModel
        open={domainModalOpen}
        setOpen={setDomainModalOpen}
        user={selectedUser}
        refetch={usersQuery.refetch}
      />

      <div className="flex flex-col space-y-5 w-[1000px]">
        <UserButton />
        <header>
          <p className="text-lg font-semibold">Users</p>
          <p className="">{users.length + " users"}</p>
        </header>
        <DataGrid
          rows={users}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
        />

        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="text"
            placeholder="Type here"
            className="input input-bordered w-full max-w-xs"
          />
        </div>
        <button
          onClick={handleAddUser}
          className="px-4 py-2 bg-secondary rounded-full text-black font-semibold"
        >
          Add User
        </button>
      </div>
    </>
  );
}
