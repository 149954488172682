import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Fragment, useEffect, useMemo, useState } from "react";
import Dropdown from "../components/dropdown";
import useDomains from "../hooks/useDomains";
import { addDomain, leaveDomain, User } from "../services/proxy";

type Props = {
  setOpen: (open: boolean) => void;
  open: boolean;
  user: User | null;
  refetch: () => void;
};

function Modal(props: Props) {
  const { setOpen, user, open, refetch } = props;
  const [selectedDomain, setSelectedDomain] = useState("");
  const domainsQuery = useDomains();

  const domains = useMemo(() => domainsQuery.data ?? [], [domainsQuery.data]);

  const close = () => setOpen(false);

  useEffect(() => {
    if (selectedDomain || !domains.length) {
      return;
    }
    setSelectedDomain(domains[0].id);
  }, [domains, selectedDomain, setSelectedDomain]);

  const handleAddDomain = async () => {
    if (!user) return;
    await addDomain(user.id, selectedDomain);
    refetch();
  };

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", flex: 2 },
    { field: "redirectUrl", headerName: "Url", flex: 4 },
    {
      field: "custom-1",
      headerName: "Details",
      flex: 1,
      renderCell: (params) => {
        return (
          <button
            onClick={async () => {
              if (!user) return;
              await leaveDomain(user.id, params.row.id);
              await refetch();
            }}
          >
            <TrashIcon className="w-5 text-neutral-500" />
          </button>
        );
      },
    },
  ];

  const userDomains = user?.domains ?? [];

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                ></Dialog.Title>

                <div className="flex flex-col space-y-8">
                  <section className="flex flex-col space-y-2">
                    <Dropdown
                      label="Domain"
                      options={domains.map((domain) => {
                        return {
                          key: domain.id,
                          label: domain.title,
                        };
                      })}
                      value={selectedDomain}
                      onChange={(e) => {
                        setSelectedDomain(e);
                      }}
                    />
                    <button
                      onClick={handleAddDomain}
                      className="font-semibold px-4 py-2 bg-secondary rounded-full hover:shadow-md space-x-1 flex items-center w-min"
                    >
                      <p>Toevoegen</p>
                      <PlusIcon className=" w-5" />
                    </button>
                  </section>

                  <section>
                    <DataGrid
                      rows={userDomains}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 15,
                          },
                        },
                      }}
                      pageSizeOptions={[15, 25, 50, 100]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </section>

                  <section className="flex justify-end">
                    <button
                      onClick={() => setOpen(false)}
                      className="px-4 py-2 bg-tertiary rounded-full text-black font-semibold"
                    >
                      OK
                    </button>
                  </section>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
