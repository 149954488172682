type Props = {
  label: string;
  options: Array<{
    key: string;
    label: string;
  }>;
  value: string;
  onChange: (key: string) => void;
};

export default function Dropdown({ label, options, value, onChange }: Props) {
  return (
    <div className="form-control w-full max-w-xs">
      <label className="label">
        <span className="label-text">{label}</span>
      </label>
      <select
        className="select select-bordered"
        onChange={(e) => {
          onChange(
            options.find((option) => option.label === e.target.value)?.key ?? ""
          );
        }}
        value={options.find((option) => option.key === value)?.label}
      >
        {options.map((option) => {
          return <option key={option.key}>{option.label}</option>;
        })}
      </select>
    </div>
  );
}
