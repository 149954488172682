import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../services/proxy";

const useHook = () => {
  return useQuery(["users"], async () => {
    return getUsers();
  });
};

export default useHook;
