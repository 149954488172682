import { client } from "./axios";

export type UsersResponse = Array<User>;

export type User = { email: string; id: string; domains: Array<Domain> };

export type Domain = {
  description: string;
  id: string;
  redirectUrl: string;
  title: string;
};

export type DomainsResponse = Array<Domain>;

export const getUsers = async () => {
  const response = await client.get<UsersResponse>("/api/admin/users", {});
  return response.data;
};

export const getDomains = async () => {
  const response = await client.get<DomainsResponse>("/api/admin/domains", {});
  return response.data;
};

export const addDomain = async (user: string, domain: string) => {
  const response = await client.post(`/api/admin/domains/${domain}/join`, {
    user,
  });
  return response.data;
};

export const leaveDomain = async (user: string, domain: string) => {
  const response = await client.delete(
    `/api/admin/domains/${domain}/users/${user}/leave`
  );
  return response.data;
};

export const createUser = async (email: string) => {
  const response = await client.post(`/api/admin/users`, {
    email,
  });
  return response.data;
};
