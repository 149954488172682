import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import { nlNL } from "@clerk/localizations";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Home from "./pages/home";

const queryClient = new QueryClient();

function App() {
  return (
    <ClerkProvider
      localization={nlNL}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
    >
      <SignedIn>
        <QueryClientProvider client={queryClient}>
          <div className="min-w-screen w-full bg-neutral-100 min-h-screen h-full flex justify-center items-center">
            <div className="bg-white rounded-md shadow-md p-12">
              <Home />
            </div>
          </div>
        </QueryClientProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>
  );
}

export default App;
